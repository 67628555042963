<template>
  <Loading />
  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">
    <n-loading-bar-provider>
      <n-message-provider :placement="messagePlacement">
        <n-notification-provider>
          <n-modal-provider>
            <n-dialog-provider>
              <ToastInjector />
              <Sidebar :menu-options="menuOptions" :logo-src="'/images/Logo-ClaimsSnap-400px.png'" title="ClaimsSnap">
                <n-layout id="default_layout_div" ref="DefaultLayoutDiv" :native-scrollbar="false">
                  <n-layout-header>
                    <Header />
                  </n-layout-header>
                  <n-layout-content :native-scrollbar="false">
                    <main class="sr__main-body" @click="collapseSidebar">
                      <div>
                        <CheckUserStatus />
                        <div class="d-flex welcome">
                          <div>
                            <h2 v-if="$auth?.businessName" class="text-white mb-1">
                              Welcome, {{ $auth?.businessName }}
                            </h2>
                            <h2 v-else class="text-white mb-1">
                              Welcome, {{ $auth?.firstName }} {{ $auth?.lastName }}
                            </h2>
                            <p class="text-gray">
                              <span>ClaimsSnap</span>
                              is fast, simple, and secure
                            </p>
                          </div>
                        </div>

                        <template v-if="$auth?.approved === false">
                          <div class="mt-4 text-left sr__not-approved">
                            <p>
                              Our team is reviewing your account for approval now. Hang in there, this shouldn't take
                              long.
                            </p>
                            <p>
                              Want to get approved faster? Email us at
                              <a :href="`mailto:support@snaprefund.io?subject=${supportMail}`" data-rel="external"
                                >Support@snaprefund.io</a
                              >
                            </p>
                          </div>
                        </template>

                        <div class="body-wrapper clearfix">
                          <div class="body-content">
                            <div class="row">
                              <slot />
                            </div>
                          </div>
                        </div>
                      </div>
                    </main>
                  </n-layout-content>
                  <n-layout-footer position="absolute" style="z-index: 990">
                    <div class="help-text">
                      <div>Need help? We're here <span style="color: red">❤</span>.</div>
                      <div>
                        Email us at
                        <a :href="`mailto:support@snaprefund.io?subject=${supportMail}`" data-rel="external"
                          >Support@snaprefund.io</a
                        >
                      </div>
                    </div>

                    <Idle style="display: none" />
                  </n-layout-footer>
                </n-layout>
              </Sidebar>
              <VerifyPhoneToast :show="isVerifyPhoneToastVisible" @hide-toast="hideToast" />
            </n-dialog-provider>
          </n-modal-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import { NImage, darkTheme } from 'naive-ui';
import type { MenuOption, NLayout } from 'naive-ui';
import { Settings } from '@vicons/tabler';
import { RouterLink } from 'vue-router';
import { getGlobalThemeOverrides } from '../../base/utils/theme-overrides';
import { DwollaCustomerType } from '../../base/common/enums/dwolla-customer-type';

const { $isSendPaymentMenuVisible } = useNuxtApp();
const router = useRouter();
const { data: $auth } = useAuth();
const profileStore = useProfileStore();
const { isVerifyPhoneToastVisible } = storeToRefs(profileStore);

const { sidebarCollapsedData: collapsed, setSidebarCollapsed } = useSidebarCollapse();

const { $sendButtonClickEvent, $sendEvent } = useNuxtApp();

let unsubscribeSendEventHook: () => void;

const supportMail = ref<string>('SnapRefund Support Request');
const DefaultLayoutDiv = ref<InstanceType<typeof NLayout> | null>(null);

const themeOverrides = getGlobalThemeOverrides();

const isWalletAvailable = computed(() => {
  return $auth.value?.type === DwollaCustomerType.Business && $auth.value?.isWalletAvailable;
});

const isStripeEnabled = computed(() => {
  return $auth.value?.type === DwollaCustomerType.Business && $auth.value?.isStripeEnabled;
});

const isSendPaymentMenuVisible = computed(() => {
  return $isSendPaymentMenuVisible($auth.value?.type);
});

const hideToast = () => {
  profileStore.$patch({ isVerifyPhoneToastVisible: false });
};

const menuOptions: MenuOption[] = [
  {
    show: isSendPaymentMenuVisible.value && !isStripeEnabled.value,
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'send-payment',
          },
        },
        { default: () => 'Send Payment' },
      ),
    key: 'send-payment',
    icon: renderIcon(h(NImage, { width: 24, src: '/images/ionic-ios-send.svg' })),
  },
  {
    show: !isStripeEnabled.value,
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'pending-payment',
          },
        },
        { default: () => 'Pending Payments' },
      ),
    key: 'pending-payment',
    icon: renderIcon(h(NImage, { width: 24, src: '/images/open-reload.svg' })),
  },
  {
    show: !isStripeEnabled.value,
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'transactions',
          },
        },
        { default: () => 'Transaction History' },
      ),
    key: 'transactions',
    icon: renderIcon(h(NImage, { width: 24, src: '/images/ionic-ios-paper.svg' })),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'bank',
          },
        },
        { default: () => 'Banks' },
      ),
    key: 'banks',
    icon: renderIcon(h(NImage, { width: 24, src: '/images/ionic-ios-card.svg' })),
  },
  {
    show: isWalletAvailable.value,
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'wallet',
          },
        },
        { default: () => 'Wallet' },
      ),
    key: 'wallet',
    icon: renderIcon(h(NImage, { width: 24, src: '/images/wallet-icon.svg' })),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'my-preference',
          },
        },
        { default: () => 'Preferences' },
      ),
    key: 'preferences',
    icon: renderIcon(Settings),
  },
];

const collapseSidebar = () => {
  if (window.innerWidth < 768 && !collapsed.value) {
    setSidebarCollapsed(true);
  }
};

onMounted(() => {
  if (DefaultLayoutDiv.value) {
    DefaultLayoutDiv.value.$el.addEventListener('click', async (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }
      if (
        event.target.nodeName === 'BUTTON' ||
        (event.target.nodeName === 'A' &&
          (event.target.getAttribute('href') === '#' || event.target.getAttribute('href') === 'javascript:void(0)'))
      ) {
        const buttonName =
          event.target.innerText.length > 0 ? event.target.innerText : event.target.ariaLabel ?? 'Undefined button';
        await $sendButtonClickEvent(buttonName);
      }
    });

    unsubscribeSendEventHook = router.beforeEach((to, from) => {
      const eventData = {
        routeChangeTo: to.path,
        routeChangeFrom: from.path,
      };
      $sendEvent(eventData, EventTypes.AppRouteChanged);
    });
    profileStore.$patch({
      isVerifyPhoneToastVisible: router.currentRoute.value.name !== 'my-preference' && !$auth.value?.phoneVerified,
    });
  }
});

onUnmounted(() => {
  unsubscribeSendEventHook();
});
</script>

<style scoped lang="scss">
.column-flex-100h {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.sr__main-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 24px;
}
.help-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  padding: 24px;
  width: 100%;
  color: white;
  font-size: 15px;
  line-height: 20px;
  border-top: 1px solid #292336;
}

@media (max-width: 767px) {
  .help-text {
    flex-direction: column;
    justify-content: center;
  }
}

.sr__not-approved {
  font-size: 24px;
  font-weight: bold;
}

.n-layout .n-layout-content {
  height: calc(100vh - 68px - 99px);
  padding: 0 !important;

  @media (min-width: 768px) {
    height: calc(100vh - 68px - 69px);
  }
}

#default_layout_div .n-layout-content {
  padding: 24px;
}

.welcome {
  margin-bottom: 24px;

  p {
    margin: 0;
  }
}

.row {
  --bs-gutter-x: 0;
}
</style>
